.App {
  display: flex;
  flex-direction: column;
}

/*make main-content to 2 col using flex. width will  be left 60% right 40%*/
.main-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.header {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
}

.header>a {
  padding: 5px 20px;
  color: #000;
  text-decoration: none;
  border-radius: 10px;
}

.header>a:hover {
  color: #885df1;
  background-color: #e3e4e5;
}

.header>a.active {
  color: #885df1;
}

.logs {
  background-color: rgba(246, 246, 246, 1);
  display: flex;
  flex-direction: column;
}

.logs>label {
  margin-left: 10px;
  margin-top: 2px;
  font-size: 16px;
}

.bg-grey {
  background-color: rgba(246, 246, 246, 1);
  position: relative;
}

.bg-grey>.eyes {
  position: absolute;
  top: 5px;
  right: 25px;
  /* padding: 5px; */
}

.blur {
  filter: blur(4px);
}

.success-button {
  background-color: #52c41a;
  border-color: #52c41a;
  color: #fff;
}

.success-button:hover,
.success-button:focus {
  background-color: #73d13d !important;
  border-color: #73d13d !important;
}

.success-button:disabled {
  cursor: not-allowed !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.cm-wrap {
  height: 300px;
  border: 1px solid silver
}

.cm-scroller {
  overflow: auto;
}

.wallet-list,
.wallet-header {
  height: 2.5em;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  /* border: 1px solid #d9d9d9; */
  /* border-radius: 6px; */
}

.wallet-table {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.action-container-buy {
  border: 2px solid #52c41a;
  border-radius: 6px;
}

.action-container-sell {
  border: 2px solid #ff4d4f;
  border-radius: 6px;
}

.logs-header {
  display: flex;
  flex-direction: row;
}

.logs-header>.title {
  display: flex;
  justify-content: start;
  align-items: center;
}

.logs-header>.clear {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  flex: auto;
}